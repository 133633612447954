import styled from "styled-components";
import { space, layout, color, flexbox, background, border, position, typography } from "styled-system";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${space};
  ${layout};
  ${color};
  ${flexbox};
  ${background};
  ${border};
  ${position};
  ${typography};
`;
