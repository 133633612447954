import styled from "styled-components";
import { border, color, layout, space, typography, variant } from "styled-system";

export const Input = styled.input`
  border: 1px solid #949494;
  color: black;
  line-height: 1.2;
  border-radius: 2px;
  padding: 9px 15px;
  font-size: 16px;
  ${layout};
  ${border};
  ${color};
  ${space};
  ${typography};
  ${variant}

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${(props) => props.whitePlaceholder && "rgba(255, 255, 255, 0.9)"};
  }

  ${variant({
    variants: {
      authentication: {
        py: 2,
        px: 3,
        width: "100%",
        mb: 3,
        border: "1px solid #b0b0b0",
        borderRadius: "30px",
      },
      signUpForm: {
        borderRadius: "30px",
        bg: "darkTeal",
        color: "white",
        px: 3,
        py: 2,
        fontSize: 16,
        width: "100%",
        border: "2px solid white",
        placeholder: "First Name",
        name: "firstName",
        mb: 3,
      },
    },
  })}
`;
