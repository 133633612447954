import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Text } from "../../elements/Text";
import { Input } from "../../elements/Input";
import { Button } from "../../elements/Button";
import { Textarea } from "../../elements/Textarea";
import { Form } from "../../elements/Form";

const ContactPage = () => {
  return (
    <>
      <Flex bg="pink" p={5} justifyContent="center">
        <Heading>Contact</Heading>
      </Flex>

      <Form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        flexDirection="column"
        textAlign="center"
        py={4}
        px={3}
        maxWidth="1000px"
        margin="0 auto"
        action="/pages/contact"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <Text fontWeight="600" mb={1}>
          Get in touch with us!
        </Text>
        <Text mb={1}>
          Whether you have a question or you want to let us know how you get on with Lilly's recipes and workouts,
          please complete the form below and we'll get back to you as soon as possible.
        </Text>
        <Text mb={4}>For order Inquiries, please include your order number (e.g. #LWL1234).</Text>

        <Text textAlign="left" mb={1}>
          Name
        </Text>
        <Input mb={2} name="name" />

        <Text textAlign="left" mb={1}>
          Email *
        </Text>
        <Input mb={2} name="email" type="email" />

        <Text textAlign="left" mb={1}>
          Message
        </Text>
        <Textarea mb={3} height="200px" name="message" />

        <Button variant="secondary">SEND</Button>
      </Form>
    </>
  );
};

export default ContactPage;
