import styled from "styled-components";
import { border, color, layout, space, typography } from "styled-system";

export const Textarea = styled.textarea`
  border: 1px solid #949494;
  color: black;
  line-height: 1.2;
  border-radius: 2px;
  padding: 9px 15px;
  font-size: 16px;
  ${layout};
  ${border};
  ${color};
  ${space};
  ${typography};
  resize: none;

  &:focus {
    outline: none;
  }
`;
